import React, { useState } from "react";
import { graphql } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";

import {cloneDeep, flatten, filter} from 'lodash';

const ProjektVirtulleum = props => {

    const language_id = 2;
    const virtulleuminfos = filter(props.data.allAboutProjectVirtulleumInfos.nodes, function(info){ return info.language_id == language_id})[0];

    return (
        <Layout pathContext={props.pathContext}>
            <SEO title="Über uns" />

            {virtulleuminfos && (
                <section className="project_virtulleum_section">
                    <div className="project_virtulleum_wrapper main_content_wrapper">
                        <h1>{virtulleuminfos.title}</h1>

                        <div
                            className="project_virtulleum_text_wrapper"
                            dangerouslySetInnerHTML={{
                                __html: virtulleuminfos.description
                            }}
                        ></div>

                        {/* <h3>Das Virtulleum Team</h3>

                        <div
                            className="project_virtulleum_text_wrapper"
                            dangerouslySetInnerHTML={{
                                __html: virtulleuminfos.team_infos
                            }}
                        ></div> */}
                    </div>

                    <div className="main_content_leaf_deco_1"></div>
                    <div className="main_content_leaf_deco_2"></div>

                    <div className="main_content_leaf_deco_3"></div>
                    <div className="main_content_leaf_deco_4"></div>

                    <div className="dust_deco_1"></div>
                    <div className="dust_deco_2"></div>
                </section>
            )}
        </Layout>
    );
};

export default ProjektVirtulleum


export const query = graphql`
    query AboutProject {
        allAboutProjectVirtulleumInfos {
            nodes {
                title
                team_infos
                language_id
                description
            }
        }
    }
`
  